import api from '../library/axios';
import config from '../config';

export default {
  getList({
    workspace_id,
    page,
    search,
    search_by,
    status,
    assigned_to,
    label_id,
    sort_by = '',
    sort_type = '',
  }) {
    return api({
      method: 'get',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}`,
      params: {
        page,
        keyword: search,
        search_by,
        status,
        assigned_to,
        label_id,
        sort_by,
        sort_type,
      },
    });
  },
  getListDetail(workspace_id, conversation_id, page = 1, without_message = undefined) {
    return api({
      method: 'get',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/${conversation_id}`,
      params: {
        page,
        without_message,
      },
    });
  },
  replyText(message, workspace_id, conversation_id) {
    return api({
      method: 'post',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/${conversation_id}/reply`,
      data: {
        type: 'text',
        message,
      },
    });
  },
  replyMessage({
    message,
    workspace_id,
    conversation_id,
    type,
    base64,
    msg_id,
    reply_id,
  }) {
    return api({
      method: 'post',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/${conversation_id}/reply`,
      data: {
        type,
        message: ['text', 'private_note'].includes(type) ? message : base64,
        caption: ['text', 'private_note'].includes(type) ? null : message,
        msg_id,
        reply_id,
      },
    });
  },
  endConversation({ workspace_id, conversation_id }) {
    return api({
      method: 'patch',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/${conversation_id}/resolve`,
      data: {
        send_closing_message: true,
      },
    });
  },
  pinChat({ workspace_id, conversation_id }) {
    return api({
      method: 'patch',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/${conversation_id}/pin`,
    });
  },
  getConversationHistory(workspace_id, contact_id, page = 1) {
    return api({
      method: 'get',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/${contact_id}/history`,
      params: {
        page,
      },
    });
  },
  getConversationMedia(workspace_id, contact_id, page = 1) {
    return api({
      method: 'get',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/${contact_id}/media`,
      params: {
        page,
      },
    });
  },
  reassignTicketAgent({
    workspace_id, conversation_id, recipient_type = 'individual', recipient,
  }) {
    return api({
      method: 'patch',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/${conversation_id}/reassign`,
      data: {
        recipient_type,
        recipient,
      },
    });
  },
  updateLabel({
    workspace_id,
    conversation_id,
    label_id,
    action,
  }) {
    return api({
      method: 'patch',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/${conversation_id}/labels`,
      data: {
        label_id,
        action,
      },
    });
  },
  takeOverUnhandled({
    workspace_id,
    conversation_id,
  }) {
    return api({
      method: 'patch',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/${conversation_id}/takeover-unhandled`,
    });
  },
  getReachTicketTemplates(workspace_id, conversation_id) {
    return api({
      method: 'get',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/${conversation_id}/reach-ticket/templates`,
    });
  },
  reachTicket({
    workspace_id,
    conversation_id,
    data,
  }) {
    return api({
      method: 'post',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/${conversation_id}/reach-ticket`,
      data,
    });
  },
  updateNotes({
    workspace_id,
    conversation_id,
    data,
  }) {
    return api({
      method: 'patch',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/${conversation_id}/notes`,
      data,
    });
  },
};
